<template>
    <div class="row">
        <div class="col-12">

            <!-- Filter -->
            <div class="accordion" id="filterAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                            Filters (Click to Open)
                        </button>
                    </h2>
                    <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                        <div class="accordion-body">
                            <Form @submit="(values, {resetForm}) => loadWhitelist(pagination.page, values, resetForm)" :validation-schema="schema" ref="form">
                                <div class="row">
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="vehicleRegistrationFilter" class="form-label">Vehicle Registration</label>
                                        <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" placeholder="Enter Registration"/>
                                        <ErrorMessage name="registration" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="row m-t-md">
                                    <div class="col-12" style="width:auto;">
                                        <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>Search</span>
                                        </button>
                                    </div>

                                    <div class="col-12" style="width:auto;">
                                        <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="reset()">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('global.clear_filter') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- Table -->
    <h1 class="table-heading"> {{$t('navigation.side.client_whitelist')}} </h1>

    <!-- loading -->
    <div v-show="loading" style="width: 100%; text-align: center;">
        <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
            <span class="visually-hidden">{{ $t('events.loading') }}...</span>
        </div>
    </div>

    <!-- Pagination -->
    <Pagination v-show="!loading" :config="pagination" @search="(page) => loadWhitelist(page, currentParams)"/>

    <!-- Client Whitelist -->
    <table class="table table-striped m-t-lg m-b-lg">
        <thead>
            <tr>
                <th scope="col"> {{$t('whitelist.registration')}} </th>
                <th scope="col"> {{$t('whitelist.notes')}} </th>
                <th scope="col"> {{$t('whitelist.added_by')}} </th>
                <th scope="col"> {{$t('whitelist.added_on')}} </th>
                <th scope="col"> {{$t('whitelist.expiry')}} </th>
                <th scope="col"> {{$t('whitelist.action')}} </th>
            </tr>
        </thead>
        <tbody>
            <tr v-show="whitelist.length < 1">
                <td colspan="6" class="no-data-container"> No data to show </td>
            </tr>
            <tr v-for="wl in whitelist" :key="'whitelist-' + wl.registration" style="vertical-align: middle;">
                <td>
                    <span class="vrm bold number-plate-gbr">{{wl.registration}}</span>
                </td>
                <td v-if="wl.notes === null" id="no-notes"> {{$t('whitelist.no_notes')}} </td>
                <td v-else>{{wl.notes}}</td>
                <td>{{wl.user.email}}</td>
                <td>
                    <timestamp :time="wl.added" format="dd/MM/yyyy HH:mm:ss"></timestamp>
                </td>
                <td>{{!!wl.expiry ? wl.expiry : 'No Expiry'}}</td>
                <td><button class="btn bg-danger btn-style-light flex-grow-1 m-1-xxs" @click="removeWhitelist(wl.registration)"><i class="material-icons">close</i>Remove</button></td>
            </tr>
        </tbody>
    </table>

    <!-- Pagination -->
    <Pagination v-show="!loading" :config="pagination" @search="(page) => loadWhitelist(page, currentParams)"/>
</template>

<script>
import * as yup from 'yup';
import {Form, Field, ErrorMessage} from 'vee-validate';
import Timestamp from '../../components/Timestamp.vue';
import Pagination from '../../components/Pagination.vue';
import WhitelistService from '../../services/whitelist.service';
export default {
  components: { Form, Timestamp, Field, ErrorMessage, Pagination },
    name: 'Whitelist',
    data(){
        const schema = yup.object().shape({
            registration: yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must be no more than 12 characters!'),
        });
        return {
            loading: true,
            whitelist: [],
            pagination: {
                page: 0,
                showing: 0,
                total: 0,
                lastPage: 0,
                maxPage: 0
            },
            schema: schema,
            currentParams: {},
        }
    },
    mounted(){
        this.loadWhitelist();
    },
    methods: {
        loadWhitelist(page = 1, params = {}) {
            this.loading = true;
            this.currentParams = params;
            WhitelistService.getClientWhitelist(page, this.$store.state.auth.user.selectedClient, params)
                .then((response) => {
                    this.pagination = response.data.pagination;
                    this.whitelist = response.data.whitelist;
                    this.loading = false; 
                })
                .catch(error => {
                    this.$error($t('whitelist.client_error'), error);
                    this.loading = false;
                })
        },
        removeWhitelist(registration) {
            WhitelistService.removeClientWhitelist(this.$store.state.auth.user.selectedClient, registration).then((response) => {
                this.whitelist = this.whitelist.filter((el) => {
                    return el.registration !== registration;
                });
            });
        },
        clearFilter(page = 1, params = {}) {
            this.loadWhitelist(page, params);
            this.$refs.form.resetForm();
        }
    }
}
</script>

<style scoped>
    #no-notes {
        color: var(--font-color-secondary);
        font-size: 14px;
        font-style: italic;
    }   
</style>